<template>
    <div>
        <div class="tab">
            <el-tabs v-model="index_code" @tab-click="tabClickHandle" style="float:left;margin-top:10px;width:98.4%;position:relative;">
                <el-tab-pane  name="HSI">
                    <span slot="label" style="margin-left:16px">{{hsi}}
                        <el-tooltip placement="bottom">
                            <div slot="content">恒生指数是从香港股票市场挑选出的50只蓝筹股构成的指数，是反映香港股票市场表现的重要指标。<br/>恒生指数是以自由流通调整的市值加权指数，单只成分股的最大权重不超过10%。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                   <Shang :data="hsi" ref="shang" v-if="'HSI'==index_code"/>
                </el-tab-pane>
                <el-tab-pane name="TWII">
                    <span slot="label">{{twii}}
                        <el-tooltip placement="bottom">
                            <div slot="content">台湾加权指数由台湾证券交易所编制，样本股为在台湾证券交易所上市的所有普通股（上市一个月之内、停止买卖的股票除外），<br/>采用派式加权法编制，是衡量台湾上市股票整体绩效表现的指标。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="twii" ref="shang" v-if="'TWII'==index_code"/>
                </el-tab-pane>
                <el-tab-pane name="N225">
                    <span slot="label">{{n225}}
                        <el-tooltip placement="bottom">
                            <div slot="content">日经225指数由在东京证券交易所上市的225家蓝筹股为样本股，采用价格加权法编制。日经225是日本股市最重要的指数，<br/>有许多金融产品跟踪该指数，并能在全世界范围内交易。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="n225" ref="shang" v-if="'N225'==index_code"/>
                </el-tab-pane>
                <el-tab-pane name="NZ50">
                    <span slot="label">{{nz50}}
                        <el-tooltip placement="bottom">
                            <div slot="content">新西兰交易所50指数是新西兰最主要的股票指数，它于2003年推出，取代了新西兰交易所40指数。<br/>该指数包含了新西兰股票市场自由流通市值最大的50只股票。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="nz50" ref="shang" v-if="'NZ50'==index_code"/>
                </el-tab-pane>                                
            </el-tabs>
            <span style="position:absolute;left:73%;height:50px;line-height:50px;">预测方法： 
                <el-radio-group v-model="method" @change="radioHangeHandler">
                    <el-radio label="garch_skewt">GARCH法</el-radio>
                    <el-radio label="ewma_hs">EWMA法</el-radio>
                </el-radio-group>                    
            </span>
        </div>
    </div>
</template>

<script>
import Shang from './shang'
export default {
    name: 'asiapacific',
    data () {
        return {
            index_code: 'HSI',
            method: 'garch_skewt',
            length: '1y',
            down_color: '#E64545',
            up_color: '#67B35A',
            hsi:'恒生指数',
            twii:'台湾加权指数',
            n225:'日经225',
            nz50:'新西兰50',
            isHushenFont: false 
        }
    },
    components:{
        Shang
    },  
    mounted(){
        this.$nextTick(() => {
            this.initEcharts()
        })
    },        
    methods: { 
        changeData:function(){
            let params = {
                "index_code": this.index_code,
                "length": this.length,
                "method": this.method,
                "down_color": this.down_color,
                "up_color":this.up_color
            };     
            this.$refs.varEsEcharts.changeData(params) ;
            this.$refs.realizedVolEcharts.changeData(params) ;
        },
        initEcharts: function () {
            //调用子组件的函数，并把这个状态传过去。
            //this.changeData()
        },             
        radioHangeHandler: function (value) {
            this.method = value
            //调用子组件的函数，并把这个状态传过去。
            this.$refs.shang.changeData()
        }, 
        tabClickHandle: function (tab) {
            this.index_code = tab.name
            //调用子组件的函数，并把这个状态传过去。
            //this.changeData() 
        },
    }
}
</script>
<style lang='less'>
.el-tabs__item.is-active, .el-tabs__item:hover {
    color: #2E5082;
}
.el-radio__input.is-checked .el-radio__inner {
    border-color: #5F80A9;
    background: #5F80A9;
}
.el-radio__input.is-checked+.el-radio__label {
    color: #5F80A9;
}
.el-tabs__active-bar {
    background-color: #2E5082;
}
</style>
<style lang='less' scoped>
.tab{
    width:116.5%;
    height:50px;
    // padding:0 16px;
    background:#F7F7F7;
    position:relative;
    border-top:solid 1px #ddd;
}
</style>